<template>
  <div class="dashboard">
    <h1>Kelin Kerak Admin paneliga Xush kelibsiz:)</h1>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  methods: {

  },
  created() {

  }
}
</script>

<style scoped>

</style>